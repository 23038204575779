<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <img src="./../../assets/erika-logo.png">
                                <validation-observer tag="div" ref="observer">
                                    <CForm v-if="!message">
                                        <zw-input-group v-model="form.new_password"
                                                        name="new_password"
                                                        :placeholder="$t('login.label.new_password')"
                                                        type="password"
                                                        label-prefix="login.label."
                                                        disable-label
                                                        :validate="{required:true,min:6}"
                                                        icon="key"
                                        ></zw-input-group>

                                        <zw-input-group v-model="form.repeat_password"
                                                        name="repeat_password"
                                                        :placeholder="$t('login.label.repeat_password')"
                                                        type="password"
                                                        label-prefix="login.label."
                                                        disable-label
                                                        :validate="{ required: true, is:this.form.new_password}"
                                                        icon="key"
                                        ></zw-input-group>

                                        <CRow>
                                            <CCol col="6" class="text-left">
                                                <CButton @click="activate()" color="primary" class="px-4">{{
                                                        $t('login.button.activate')
                                                    }}
                                                </CButton>
                                            </CCol>
                                            <CCol col="6" class="text-right">
                                                <CButton to="/login" color="link" class="px-0">
                                                    {{ $t('login.button.login') }}
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>

                                    <div v-else>
                                        <b-alert show variant="warning">{{
                                                $t('login.message.token_expired')
                                            }}
                                        </b-alert>
                                        <CRow>
                                            <CCol col="12" class="text-right">
                                                <CButton to="/login" color="link" class="px-0">
                                                    {{ $t('login.button.login') }}
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </div>
                                </validation-observer>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
export default {
    name: 'Activate',
    data() {
        return {
            form: {
                new_password: null,
                repeat_password: null,
                token: null,
            },
            message: false,
        }
    },
    mounted() {
        this.$root.$children[0].loading = false
        this.form.token = this.$route.params.hash
    },
    methods: {
        activate() {
            window.axios.post(window.apiUrl + '/activate', this.form, {'skip_loading': true})
                .then(response => {
                    if (response.data.status) {
                        window.location.href = 'https://' + response.data.domain + '.mypapertools.com/v2/#login';
                    } else {
                        this.message = true
                    }
                })
        }
    }
}
</script>

<style lang="scss">
body {
    background-image: url('./../../assets/web_background-erika.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.c-app {
    background-color: transparent;
}
</style>